<template>
  <v-app backgroundColor="#673AB7">
    <NavBar></NavBar>
    <v-main transition="slide-x-transition" class="bg-body">
      <router-view></router-view>
    </v-main>
    <v-snackbar v-model="$store.getters.snackBar.active" :timeout="3000" :color="$store.getters.snackBar.color"
      :top="true" centered>
      {{ $store.getters.snackBar.message }}
      <v-btn text small dark @click="closeSnackBar()">
        Close
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
export default {
  components: {
    NavBar,
  },

  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    closeSnackBar() {
      this.snackBar({ enable: false, message: "" });
    }
  },
};
</script>
<style lang="css" scoped>
.bg-body {
  background-color: #f6f9fc;
}
</style>