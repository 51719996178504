<template>
  <div>
    <v-snackbar
      v-model="type"
      :timeout="3000"
      :color="snackbarcolor"
      :top="true"
    >
      {{ this.message }}
      <v-btn color="pink" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-form @submit.prevent="formSubmit" ref="registerForm" v-model="formValid" lazy-validation>
            <v-card class="mx-auto" >
              <v-card-title>
                <v-icon>mdi-account</v-icon> Register a Vendor
              </v-card-title>

              <v-card-text>
                 <v-row class="ml-2 mr-2">
                  <v-text-field
                    label="E-Mail*"
                    v-model="email"
                    required
                    autofocus
                    name="Email"
                    type="text"
                    ref="email"
                    :rules="validateRules.email"
                    prepend-icon="mdi-email-outline"
                  ></v-text-field>
                  <v-btn
                    class="mt-4 ml-2"
                    rounded
                    small
                    @click="formSubmit"
                    color="primary"
                  >
                    <v-progress-circular
                      v-if="loading"
                      indeterminate
                      :size="15"
                      :width="2"
                      color="white"
                    ></v-progress-circular>
                    <span v-if="loading">&nbsp;&nbsp;Loading...</span>
                    <span v-if="!loading">Check Email</span>
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
              </v-form>
              <v-form v-if="!is_email_exists"
                @submit.prevent="registerSubmit"
                ref="registerFormOther"
                v-model="formOtherValid"
                lazy-validation
                class="ml-6 mr-6 mt-4"
              >
                <v-row >
                  <v-text-field
                    label="First Name*"
                    v-model="firstName"
                    required
                    prepend-icon="mdi-account"
                    name="First Name"
                    @blur="$v.$touch()"
                    :error-messages="getErrors('firstName', $v.firstName)" 
                    type="text"
                  ></v-text-field>
                </v-row>
                <v-row >
                  <v-text-field
                    label="Last Name"
                    v-model="lastName"
                    prepend-icon="mdi-account"
                    name="Last Name"
                    @blur="$v.$touch()"
                    type="text"
                  ></v-text-field>
                </v-row>
                <v-row >
                  <v-text-field
                    label="Inital/Father Name/Sur Name"
                    v-model="surName"
                    prepend-icon="mdi-account"
                    name="Initial"
                    @blur="$v.$touch()"
                    type="text"
                  ></v-text-field>
                </v-row>
                <v-row >
                  <v-text-field
                    label="Mobile*"
                    v-model="mobile" 
                    required
                    prepend-icon="mdi-cellphone-basic"
                    name="Mobile"
                    type="number"
                    :error-messages="getErrors('mobile', $v.mobile)" 
                    @blur="$v.$touch()"
                 ></v-text-field>
                </v-row>
                <v-row >
                  <v-text-field
                    label="Password*"
                    v-model="password"
                    id="password"
                    type="password"
                    required
                    ref="password"
                    :error-messages="getErrors('password', $v.password)" 
                    @blur="$v.$touch()"
                    prepend-icon="mdi-account-key"
                    ></v-text-field>
                </v-row>
                 <v-row >
                  <v-text-field
                    label="Confirm Password*"
                    v-model="confirmPassword"
                    id="cpassword"
                    type="password"
                    required
                    ref="cpassword"
                    :error-messages="getErrors('confirmPassword', $v.confirmPassword)" 
                    @blur="$v.$touch()"
                    prepend-icon="mdi-account-key"
                    ></v-text-field>
                </v-row>
                <v-row class="mt-2 ml-2 float-right">
                  <v-btn
                    small
                    type="button"
                    color="primary"
                    @click="registerSubmit"
                  >
                    <v-progress-circular
                      v-if="otherLoading"
                      indeterminate
                      :size="15"
                      :width="2"
                      color="white"
                    ></v-progress-circular>
                    <span v-if="otherLoading">&nbsp;&nbsp;Loading...</span>
                    <span v-if="!otherLoading">Register</span>
                  </v-btn>
                </v-row>
              </v-form>
           
          </v-flex>
          </v-layout>
        </v-container>
  </div>
</template>
<script>
import axios from "axios";
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      genderList: ["MALE", "FEMALE"],
      loading: false,
      otherLoading: false,
      validateRules: {
        email: [(v) => !!v || "Email is required",
         v => /.+@.+/.test(v) || 'Email must be valid',],
      },
      formValid: false,
      formOtherValid: false,
      message: "",
      type: "",
      snackbarcolor: "",
      firstName: "",
      lastName: "",
      surName: "",
      gender: "",
      userRole: "Vendor",
      username: "",
      password: "",
      confirmPassword : '',
      password_confirmation: "",
      mobile: "",
      email: "",
      is_email_exists: true,
      roles: "",
    };
  },
  validations: {
    firstName: {  required },
    mobile: {  required },
    password: { required, minLength: minLength(6) },
    confirmPassword: { required, sameAsPassword: sameAs('password') }
  },
  methods: {
    getErrors(name, m) {
      const errors = [];
      if (!m.$dirty) return errors;
      switch (name) {
        case "firstName":
          !m.required && errors.push("First name is required.");
          break;
        case "mobile":
          !m.required && errors.push("Mobile is required.");
          break;
        case "password":
          !m.required && errors.push("Password is required.");
          !m.minLength && errors.push("Password must be greater than 6 characters");

          break;
        case "confirmPassword":
          !m.required && errors.push("Confirm Password is required.");
          !m.sameAsPassword && errors.push("Password and Confirm Password should match.");
          break;
        default:
          break;
      }
      return errors;
    },
    formSubmit() {
        if (!this.loading && this.$refs.registerForm && this.$refs.registerForm.validate()) {
          this.validateEmail();
        }
    },
    registerSubmit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      } else if(!this.loading && !this.otherLoading){
        this.register();
      }
    },
    validateEmail() {
      this.loading = true;
      axios({
        url: process.env.VUE_APP_BASE_URL + "/avocet/api/v1/auth/validate",
        params: {
          email: this.email,
        },
        method: "GET",
      })
        .then((response) => {
          this.loading = false;
          this.is_email_exists = response.data;
          if (this.is_email_exists) {
            this.type = this.is_email_exists;
            this.$refs.email.focus();
            this.email = "";
            this.is_email_exists = true;
            this.snackbarcolor = "red";
            this.message = "Email Already Exists";
          } else {
            this.$refs.password.focus();
            this.is_email_exists = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    register: function () {
      this.otherLoading = true;
      let data = {
        firstName: this.firstName,
        lastName: this.lastName,
        userName: this.firstName + this.lastName,
        surName: this.surName,
        gender: this.gender,
        email: this.email,
        password: this.password,
        mobile: this.mobile,
        appUserRole: "ADMIN",
        active: false,
        user_images: {
          image:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=",
        },
        // ledgerId: this.is_email_exists.id
      };
      this.$store
        .dispatch("register", data)
        .then(() => {
          this.type = true;
          this.snackbarcolor = "success";
          this.message = "Email Registered Successfully";
          setTimeout(
            function () {
              this.otherLoading = false;
              this.$router.push("/").catch(() => {});
            }.bind(this),
            2000
          );
        })
        .catch((err) => {
          if (err.response) {
            this.type = true;
            this.snackbarcolor = "red";
            this.message = err.response ? err.response.data.status : {};
          }
          this.otherLoading = false;
        });
    },
  },
};
</script>
