import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import service from "./utils/http-common";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: {},
    currency_type: "INR",
    snackbar: { active: false, color: "", message: "" },
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token) {
      state.status = "success";
      state.token = token;
      //state.user = state.user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    setCurrency(state, value) {
      state.currency_type = value;
    },
    SET_SNACKBAR(state, snackbar) {
      state.snackbar = snackbar;
    },
  },

  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        service({ requiresAuth: false })
          .post("/avocet/api/v1/auth/signin", user)
          .then((resp) => {
            const token = resp.data.accessToken;
            const user = resp.data.username;
            const user_id = resp.data.id;
            const user_email = resp.data.email;
            const user_role = resp.data.roles[0];
            localStorage.setItem("token", token);
            localStorage.setItem("id", user_id);
            axios.defaults.headers.common.Authorization = token;
            commit("auth_success", token, user);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url:
            process.env.VUE_APP_BASE_URL + "/avocet/api/v1/auth/registration",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.token;
            const user = resp.data.user;

            localStorage.setItem("token", token);
            // Add the following line:
            axios.defaults.headers.common.Authorization = token;
            commit("auth_success", token, user);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error", err);
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise<void>((resolve, reject) => {
        commit("logout");

        // /logout/access

        localStorage.removeItem("token");
        delete axios.defaults.headers.common.Authorization;
        resolve();
      });
    },
    snackBar({ commit }, value) {
      commit("SET_SNACKBAR", {
        active: value.enable,
        color: value.color,
        message: value.message,
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    currencyType: (state) => state.currency_type,
    snackBar: (state) => state.snackbar,
  },
});
